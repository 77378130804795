<template>
    <div class="col-right">
        <div class="ng-star-inserted">
            <div class="ng-star-inserted">
                <div class="cell-info">
                    <div class="row-profile">
                        <div class="col-logo">
                            <dl>
                                <dt @click="getPayment"><img alt="" :src="buyerIndexData.company.enterpriseLogo">
                                </dt>
                            </dl>
                        </div>
                        <div class="col-name">
                            <dl>
                                <dt>{{buyerIndexData.company.enterpriseName}}</dt>
                                <dd>{{buyerIndexData.company.unifiedSocialCreditCode}}<span>企业</span></dd>
                            </dl>
                        </div>
                    </div>
                    <div class="row-count">
                        <div class="count-title">
                            <a-dropdown>
                                <a class="ant-dropdown-link" @click="e => e.preventDefault()">{{currentTime}}
                                    <a-icon type="down"/>
                                </a>
                                <a-menu slot="overlay">
                                    <a-menu-item v-for="(item, idx) in timeList" :key="idx" @click="changeTime(idx)">
                                        {{item.title}}
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                            <div class="datetime">{{buyerOrderOverall[currentKey].time}}</div>
                        </div>
                        <div class="count-body">
                            <dl>
                                <dt>
                                    <div class="item-text">采购额(元)</div>
                                    <div class="item-icon">
                                        <a-icon type="question-circle"/>
                                    </div>
                                </dt>
                                <dd class="ng-star-inserted">{{buyerOrderOverall[currentKey].purchaseOrderAmount}}</dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div class="item-text">采购订单数</div>
                                    <div class="item-icon">
                                        <a-icon type="question-circle"/>
                                    </div>
                                </dt>
                                <dd class="ng-star-inserted">{{buyerOrderOverall[currentKey].purchaseOrderNum}}</dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div class="item-text">采购商品数</div>
                                    <div class="item-icon">
                                        <a-icon type="question-circle"/>
                                    </div>
                                </dt>
                                <dd class="ng-star-inserted">{{buyerOrderOverall[currentKey].purchaseGoodsNum}}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="ant-card">
                    <div class="ant-card-head ng-star-inserted">
                        <div class="ant-card-head-wrapper">
                            <div class="ant-card-head-title ng-star-inserted">
                                <div class="page-right-title ng-star-inserted">
                                    <div class="item-title">最新订单</div>
                                    <div class="item-sub"></div>
                                    <div class="item-btn"><a href="/buyer/order-list">全部订单&gt;</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ant-card-body">
                        <div class="order-list ng-star-inserted">
                            <ul class="ng-star-inserted" v-for="(item, idx) in orderList" :key="idx">
                                <li class="ng-star-inserted">
                                    <div class="item-img">
                                        <img :src="item.orderList[0].goods_logo">
                                    </div>
                                    <div class="item-title">
                                        <dl>
                                            <dt class="ng-star-inserted">{{item.orderList[0].goods_title}}</dt>
                                            <dd>{{item.order_no}} | {{item.create_at}}</dd>
                                        </dl>
                                    </div>
                                    <div class="item-flex">￥{{item.pay_price}}</div>
                                    <div class="item-flex">{{item.status_desc}}</div>
                                    <div class="item-flex"><a @click="$jumpTo('/buyer-detail/order/'+item.id)">订单详情</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    export default {
        name: "index",
        data() {
            return {
                currentTime: '今日',
                timeList: [
                    {title: "今日", key: 'today'},
                    {title: "昨日", key: 'yesterday'},
                    {title: "本月", key: 'month'},
                ],
                buyerIndexData: {},
                orderList:[],
                buyerOrderOverall:{},
                currentKey:'today'
            }
        },
        created() {
            this.getBuyerIndex();
            this.getNewOrder();
            this.getBuyerOrderOverall();
        },
        methods: {
            getPayment(){
                service.post('http://dev.myhb.com/api/index/b2bPayment').then(res => {
                    console.log(res)
                });
            },
            getBuyerIndex() {
                service.post(service.uri.user.getBuyerIndex).then(res => {
                    this.buyerIndexData = res.data;
                });
            },
            changeTime(idx) {
                let current = this.timeList[idx]
                this.currentTime = current.title;
                this.currentKey = current.key;
            },
            getNewOrder(){
                this.spinning = true;
                service.post(service.uri.order.getList, {page:1, pageSize: 6}).then(res => {
                    if (res.code == 200) {
                        this.orderList = res.data.list;
                    }
                    this.spinning = false;
                });
            },
            getBuyerOrderOverall(){
                service.post(service.uri.order.buyerOrderOverall).then(res => {
                    if (res.code == 200) {
                        this.buyerOrderOverall = res.data;
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>


    .col-right {
        flex: 1;
        margin: 20px 0 0 20px;
        max-width: 1000px;

        .cell-info {
            display: flex;
            padding-bottom: 20px;
        }

        .row-profile {
            width: 440px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            height: 140px;
            background-color: #fff;

            .col-logo {
                padding-left: 43px;
                display: flex;
                align-items: center;
                justify-content: center;

                dl, dt {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }

                dt {
                    img {
                        display: block;
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                        margin: 0 auto;
                    }
                }
            }

            .col-name {
                padding: 0 20px 0 12px;
                flex: 1;

                dl {
                    line-height: 24px;

                    dt {
                        color: #333;
                        font-size: 14px;
                        padding-bottom: 10px;
                    }

                    dd {
                        font-size: 12px;
                        color: #999;
                        line-height: 20px;

                        span {
                            display: inline-block;
                            margin-left: 7px;
                            line-height: 20px;
                            color: #e7c38a;
                            padding: 0 6px;
                            background: #323238;
                        }
                    }
                }
            }

        }
    }

    .row-count {
        flex: 1;
        background-color: #fff;
        height: 140px;

        .count-title {
            height: 30px;
            line-height: 30px;
            border-bottom: 1px solid #ededed;
            padding: 0 0 0 10px;
            display: flex;

            .datetime {
                padding-left: 20px;
                font-size: 12px;
            }

        }

        .count-body {
            display: flex;
            padding: 24px 20px 0;
            justify-content: space-between;

            dl {
                flex: 1;
                display: block;

                dt {
                    font-size: 12px;
                    color: #666;
                    line-height: 20px;
                    display: flex;
                    align-items: center;

                    .item-icon {
                        font-size: 14px;
                        color: #999;
                        padding-left: 4px;
                    }
                }
            }
        }
    }

    .ant-card-head {

        .ant-card-head-wrapper {

            .ant-card-head-title {
                display: inline-block;
                flex: 1;
                padding: 16px 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                .page-right-title {
                    display: flex;
                    align-items: center;

                    .item-title {
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                    }

                    .item-sub {
                        font-size: 12px;
                        color: #999;
                        padding-left: 10px;
                        flex: 1;
                    }

                    .item-btn {
                        a {
                            font-size: 14px;
                            color: #666;
                        }
                    }
                }
            }
        }
    }


    .ant-card-body {

        .order-list {
            min-height: 680px;

            li {
                background: #fff;
                border: 1px solid #ededed;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                padding: 10px;
                font-size: 14px;
                color: #333;

                .item-flex {
                    flex: 1;
                    text-align: center;
                }

                .item-img {
                    width: 66px;
                    height: 66px;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-title {
                    width: 442px;
                    padding: 0 10px;

                    dt {
                        line-height: 24px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    dd {
                        font-size: 12px;
                        color: #999;
                        padding-top: 6px;
                    }
                }
            }
        }
    }

    .anticon {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .ant-select {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, .65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        width: 100%;
        height: 32px;
        padding: 0 11px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
        right: 25px;
    }
</style>